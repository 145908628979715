import React, { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { Product, SubSkuContentEntry } from "../../../types/apiResponses";
import createInputField from "../../../util/createInputField";
import { createObjectId } from "../../../util/createId";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import {
  InlineEditColumn,
  InlineEditHead
} from "../../../style-guide/InlineEdit/InlineEdit";
import Section from "../../../style-guide/Section/Section";
import Ruler from "../../../style-guide/Ruler/Ruler";
import Fader from "../../../style-guide/Fader/Fader";
import Card from "../../../style-guide/Card/Card";
import { Button } from "../../../style-guide/Button/Button";
import Block from "../../../style-guide/Block/Block";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Text from "../../../style-guide/Text/Text";

type SubSkus = Pick<Product, "subSkus" | "name">;

interface Props {
  publisher: UsePublisherInterface<SubSkus>;
}

const SubSkus: React.FC<Props> = ({ publisher }) => {
  const [newId, setNewId] = useState<string | null>(null);
  const form = publisher.form;
  const readOnly = publisher.isRevision;

  return (
    <Fader>
      <Section hugTop>
        <Card>
          <Block>
            <Flex>
              <FlexKid flex={1}>
                <Text element="h2" variant="title">
                  Inkluderte SKU-nummer
                </Text>
                <Text variant="body3" gutterTop>
                  Legg til SKU-nummer med antall som er inkludert i denne ordren
                  ved kjøp av {publisher.form.data.name}.
                </Text>
                <Text variant="body3">
                  Dette er <strong>kun</strong> synlig for de som administrerer
                  ordrene, ikke for kundene.
                </Text>
              </FlexKid>

              {!readOnly && (
                <FlexKid>
                  <Button
                    type="button"
                    outlined
                    onClick={() => {
                      const id = createObjectId();

                      form.setField({
                        path: "subSkus",
                        value: [{ _id: id }, ...(form.data.subSkus || [])],
                        submit: true
                      });

                      setNewId(id);
                    }}
                  >
                    Ny oppføring
                  </Button>
                </FlexKid>
              )}
            </Flex>
          </Block>
          <InlineEditHead>
            <InlineEditColumn width="400px">SKU</InlineEditColumn>
            <InlineEditColumn width="400px">Antall</InlineEditColumn>
          </InlineEditHead>

          {(form.data.subSkus || []).length > 0 ? (
            form.data.subSkus!.map((skuItem, k) => {
              return (
                <SubSku
                  key={skuItem._id}
                  skuItem={skuItem}
                  isNew={skuItem._id === newId}
                  readOnly={readOnly}
                  onChange={(data) =>
                    form.setField({
                      path: "subSkus",
                      value: form.data.subSkus!.map((item, kk) => {
                        if (kk === k) {
                          return data;
                        }

                        return item;
                      }),
                      submit: true
                    })
                  }
                  onDelete={() => {
                    if (window.confirm("Er du sikker på at du vil slette?")) {
                      form.setField({
                        path: "subSkus",
                        value: form.data.subSkus!.filter((_, kk) => kk !== k),
                        submit: true
                      });
                    }
                  }}
                />
              );
            })
          ) : (
            <>
              <Ruler />
              <Block hugTop>
                <Text variant="subheading">Ingen SKUer lagt til</Text>
              </Block>
            </>
          )}
        </Card>
      </Section>
    </Fader>
  );
};

interface SubSkuProps {
  skuItem: SubSkuContentEntry;
  isNew: boolean;
  onChange: (attribute: SubSkuContentEntry) => any;
  onDelete: () => any;
  readOnly?: boolean;
}

const SubSku: React.FC<SubSkuProps> = ({
  skuItem,
  isNew,
  onChange,
  onDelete,
  readOnly
}) => {
  return (
    <InlineEdit
      expanded={isNew}
      alignItems="flex-start"
      actions={[
        <Button
          type="button"
          aria-label="Slett"
          circular
          outlined
          smaller
          disabled={readOnly}
          onClick={onDelete}
        >
          <AiOutlineDelete />
        </Button>
      ]}
      headerColumns={[
        {
          width: "400px",
          node: createInputField({
            key: "sku",
            type: "text",
            required: true,
            hugTop: true,
            autoFocus: isNew,
            readOnly,
            value: skuItem.sku,
            onChange: (value) =>
              onChange({
                ...skuItem,
                sku: value
              })
          })
        },
        {
          width: "150px",
          node: createInputField({
            key: "amount",
            type: "number",
            required: true,
            hugTop: true,
            readOnly,
            value: skuItem.amount,
            onChange: (value) =>
              onChange({
                ...skuItem,
                amount: value
              })
          })
        }
      ]}
    />
  );
};

export default SubSkus;
